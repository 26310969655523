import React, { Suspense } from 'react';
import { useCookies } from "react-cookie";
import { ToastContainer } from "react-toastify";
import "./assets/style/Main.scss";
import "./assets/style/bootstrap-grid.min.css";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import ProtectedRoute from "./components/ProtectedRoute";
const Premium = React.lazy(() => import('./components/Premium'));
const Dashboard = React.lazy(() => import('./components/Dashboard'));
const Login = React.lazy(() => import('./components/Auth/Loginform'));
const Advertise = React.lazy(() => import('./components/Advertising'));
const EventsCalendar = React.lazy(() => import('./components/EventsCalendar'));
const Coinlisting = React.lazy(() => import('./components/CoinListing'));
const UserContacts = React.lazy(() => import('./components/UserContacts'));
const GenerateJson = React.lazy(() => import('./components/GenerateJson'));
const QuarterlyJson = React.lazy(() => import('./components/QuarterlyJson'));
const RedirectCoins = React.lazy(() => import('./components/RedirectCoins'));
const EditCoin = React.lazy(() => import('./components/CoinListing/EditCoin'));
const Exchanges = React.lazy(() => import('./components/CoinListing/Exchanges'));

function App() {

    const [cookies, setCookie] = useCookies(["usr"]);

    return (
        <>
            <Router>
                <ToastContainer theme="colored" />
                <Suspense fallback={<div></div>}>
                    <Routes>
                        <Route path="/dashboard" element={<ProtectedRoute activePage={'dashboard'}><Dashboard /></ProtectedRoute>} exact />
                        <Route path="/coinlisting" element={<ProtectedRoute activePage={'coinlisting'}><Coinlisting /></ProtectedRoute>} exact />
                        <Route path="/coinlisting/page/:num" element={<ProtectedRoute activePage={'coinlisting'}><Coinlisting /></ProtectedRoute>} exact />
                        <Route path="/editcoin/:id" element={<ProtectedRoute activePage={'editcoin'}><EditCoin /></ProtectedRoute>} exact />
                        <Route path="/editcoin" element={<ProtectedRoute activePage={'editcoin'}><EditCoin /></ProtectedRoute>} exact />
                        <Route path="/redirectcoins" element={<ProtectedRoute activePage={'redirectcoins'}><RedirectCoins /></ProtectedRoute>} exact />
                        <Route path="/exchanges" element={<ProtectedRoute activePage={'exchanges'}><Exchanges /></ProtectedRoute>} exact />
                        <Route path="/exchanges/page/:num" element={<ProtectedRoute activePage={'exchanges'}><Exchanges /></ProtectedRoute>} exact />
                        <Route path="/generatejson" element={<ProtectedRoute activePage={'generatejson'}><GenerateJson /></ProtectedRoute>} exact />
                        <Route path="/quarterlyjson" element={<ProtectedRoute activePage={'quarterlyjson'}><QuarterlyJson /></ProtectedRoute>} exact />
                        <Route path="/usercontacts" element={<ProtectedRoute activePage={'usercontacts'}><UserContacts /></ProtectedRoute>} exact />
                        <Route path="/usercontacts/page/:num" element={<ProtectedRoute activePage={'usercontacts'}><UserContacts /></ProtectedRoute>} exact />
                        <Route path="/eventscalendar" element={<ProtectedRoute activePage={'eventscalendar'}><EventsCalendar /></ProtectedRoute>} exact />
                        <Route path="/advertise" element={<ProtectedRoute activePage={'advertise'}><Advertise /></ProtectedRoute>} exact />
                        <Route path="/advertise/page/:num" element={<ProtectedRoute activePage={'advertise'}><Advertise /></ProtectedRoute>} exact />
                        <Route path="/premium" element={<ProtectedRoute activePage={'premium'}><Premium /></ProtectedRoute>} exact />
                        <Route path="/premium/page/:num" element={<ProtectedRoute activePage={'premium'}><Premium /></ProtectedRoute>} exact />
                        <Route exact path="/" element={cookies.usr ? <Navigate to="/dashboard" /> : <Login />} />
                    </Routes>
                </Suspense>
            </Router>
        </>
    );
}

export default App;