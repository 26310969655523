import { Navigate } from "react-router-dom";
import React, { useState } from 'react';
import { useCookies } from "react-cookie";
import Sidebar from "../components/Common/Sidebar";

const ProtectedRoute = ({ activePage, children }) => {

    const [cookies] = useCookies(["usr"]);
    const [isLoggedIn] = useState(cookies.usr !== undefined && cookies.usr.email !== undefined && cookies.usr.token !== '' ? true : false);
    if (isLoggedIn) {
        return <><Sidebar activePage={activePage} />{children}</>;
    } else {
        return <Navigate to='/' />;
    }
};
export default ProtectedRoute;