import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import Logo from "../../assets/images/logo-dark.svg";
import Sm_Logo from "../../assets/images/logo192.png";
import { RxDashboard, RxListBullet, RxPencil2, RxExternalLink, RxFilePlus, RxFileText, RxAvatar, RxBarChart, RxBookmark, RxDragHandleDots2, RxCaretRight, RxCalendar } from "react-icons/rx";

const Sidebar = (props) => {

    const [Button, setButton] = useState(false);

    useEffect(() => {
        if (Button) {
            document.body.classList.add("Slideopen");
        } else {
            document.body.classList.remove("Slideopen");
        }
    }, [Button]);

    return (
        <>
            <nav className={"Sidebar " + (Button ? "active" : "")}>
                <div className="Img_text">
                    <Link to="/">
                        <img className="Image_lg" src={Logo} alt="logo" />
                        <img className="Image_sm" src={Sm_Logo} alt="logo" />
                    </Link>
                    <button onClick={() => setButton(!Button)} type="button" className={"Manu_button " + (Button ? "active" : "")}>
                        <span className="Next Primera"><RxCaretRight /></span>
                        <span className="Next Segunda"><RxCaretRight /></span>
                    </button>
                </div>
                <div className="Sidebar_menu">
                    <ul className="nav">
                        <li className={props.activePage === "dashboard" ? "li_list active" : "li_list"}>
                            <Link to="/dashboard">
                                <b></b>
                                <b></b>
                                <span className="Menu_icon"><RxDashboard /></span>
                                <span className="Menu_title">Dashboard</span>
                            </Link>
                        </li>
                        <li className={props.activePage === "coinlisting" ? "li_list active" : "li_list"}>
                            <Link to="/coinlisting">
                                <b></b>
                                <b></b>
                                <span className="Menu_icon"><RxListBullet /></span>
                                <span className="Menu_title">Coin Listing</span>
                            </Link>
                        </li>
                        <li className={props.activePage === "eventscalendar" ? "li_list active" : "li_list"}>
                            <Link to="/eventscalendar">
                                <b></b>
                                <b></b>
                                <span className="Menu_icon"><RxCalendar /></span>
                                <span className="Menu_title">Events Calendar</span>
                            </Link>
                        </li>
                        <li className={props.activePage === "editcoin" ? "li_list active" : "li_list"}>
                            <Link to="/editcoin">
                                <b></b>
                                <b></b>
                                <span className="Menu_icon"><RxPencil2 /></span>
                                <span className="Menu_title">Edit Coin</span>
                            </Link>
                        </li>
                        <li className={props.activePage === "redirectcoins" ? "li_list active" : "li_list"}>
                            <Link to="/redirectcoins">
                                <b></b>
                                <b></b>
                                <span className="Menu_icon"><RxExternalLink /></span>
                                <span className="Menu_title">Redirect Coins</span>
                            </Link>
                        </li>
                        <li className={props.activePage === "exchanges" ? "li_list active" : "li_list"}>
                            <Link to="/exchanges">
                                <b></b>
                                <b></b>
                                <span className="Menu_icon"><RxBarChart /></span>
                                <span className="Menu_title">Exchanges</span>
                            </Link>
                        </li>
                        <li className={props.activePage === "generatejson" ? "li_list active" : "li_list"}>
                            <Link to="/generatejson">
                                <b></b>
                                <b></b>
                                <span className="Menu_icon"><RxFilePlus /></span>
                                <span className="Menu_title">Generate JSON</span>
                            </Link>
                        </li>
                        <li className={props.activePage === "quarterlyjson" ? "li_list active" : "li_list"}>
                            <Link to="/quarterlyjson">
                                <b></b>
                                <b></b>
                                <span className="Menu_icon"><RxFileText /></span>
                                <span className="Menu_title">Quarterly JSON</span>
                            </Link>
                        </li>
                        <li className={props.activePage === "usercontacts" ? "li_list active" : "li_list"}>
                            <Link to="/usercontacts">
                                <b></b>
                                <b></b>
                                <span className="Menu_icon"><RxAvatar /></span>
                                <span className="Menu_title">User Contacts</span>
                            </Link>
                        </li>
                        <li className={props.activePage === "advertise" ? "li_list active" : "li_list"}>
                            <Link to="/advertise">
                                <b></b>
                                <b></b>
                                <span className="Menu_icon"><RxDragHandleDots2 /></span>
                                <span className="Menu_title">Advertise</span>
                            </Link>
                        </li>
                        <li className={props.activePage === "premium" ? "li_list active" : "li_list"}>
                            <Link to="/premium">
                                <b></b>
                                <b></b>
                                <span className="Menu_icon"><RxBookmark /></span>
                                <span className="Menu_title">Premium</span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </nav>
        </>
    );
};
export default Sidebar;